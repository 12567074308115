import React, { useState, Fragment, useRef, useEffect } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { Tab } from '@headlessui/react';
import { PlusIcon, ChevronDownIcon, ChevronUpIcon, FlagIcon, PencilIcon } from '@heroicons/react/24/solid';
import axios from 'axios';
import Cookies from 'js-cookie';
import imageCompression from 'browser-image-compression';
import ViewImage from './ViewImage';
import { savePhoto, getPhotos, clearPhotos } from '../utils/indexedDBUtil';
import LoadingScreen from './LoadingScreen';

function ViewPhotos({ open, setOpen, project, refreshProject, setCachedPhotos }) {
    const [selectedSection, setSelectedSection] = useState(0);
    const [collapsedCollections, setCollapsedCollections] = useState({});
    const fileInputRef = useRef(null);
    const [currentRowId, setCurrentRowId] = useState(null);
    const [showingImage, setShowingImage] = useState(false);
    const [imageToShow, setImageToShow] = useState(null);
    const [adminMode, setAdminMode] = useState(Cookies.get('uid') !== undefined);
    const [flaggedRows, setFlaggedRows] = useState({});
    const [loading, setLoading] = useState(false);
    const [savingComment, setSavingComment] = useState(null)
    const [savingNote, setSavingNote] = useState(null)
    const [openCrewNotes, setOpenCrewNotes] = useState([])
    const [openFeedbackRows, setOpenFeedbackRows] = useState([])

    const toggleCollapse = (collectionId) => {
        setCollapsedCollections((prevState) => ({
            ...prevState,
            [collectionId]: !prevState[collectionId],
        }));
    };

    const handlePhotoUpload = (rowId) => {
        setCurrentRowId(rowId);
        fileInputRef.current.click();
    };

    const incrementImage = (increment) => {
        const newImageToShow = { ...imageToShow };
        newImageToShow.index += increment;
        if (newImageToShow.index < 0) {
            newImageToShow.index = 0;
        } else if (newImageToShow.index >= newImageToShow.row.photos.length) {
            newImageToShow.index = newImageToShow.row.photos.length - 1;
        }
        newImageToShow.url = newImageToShow.row.photos[newImageToShow.index].url;
        newImageToShow.id = newImageToShow.row.photos[newImageToShow.index].photo_id;
        setImageToShow(newImageToShow);
    };

    const [rowComments, setRowComments] = useState({});
    const [crewNotes, setCrewNotes] = useState({})


    useEffect(() => {
        if (project && project.sections) {
            const commentsMap = {};
            const crewNotesMap = {}
            project.sections.forEach(section => {
                section.rows.forEach(row => {
                    commentsMap[row.project_section_row_id] = row.comments || '';
                    crewNotesMap[row.project_section_row_id] = row.crew_notes || '';
                });
                section.collections.forEach(collection => {
                    collection.rows.forEach(row => {
                        commentsMap[row.project_section_row_id] = row.comments || '';
                        crewNotesMap[row.project_section_row_id] = row.crew_notes || '';
                    })
                });
            });
            setRowComments(commentsMap);
            setCrewNotes(crewNotesMap)
        }
    }, [project]);

    const updateRowComment = (project_section_row_id, newComment) => {
        setRowComments(prevComments => ({
            ...prevComments,
            [project_section_row_id]: newComment
        }));
    };

    const updateRowNote = (project_section_row_id, newNote) => {
        setCrewNotes(prevNotes => ({
            ...prevNotes,
            [project_section_row_id]: newNote
        }));
    };

    const handleFileChange = async (event) => {
        const files = Array.from(event.target.files);
        const photosToAdd = [];

        const compressAndReadFile = async (file) => {
            const options = {
                maxSizeMB: 1,
                maxWidthOrHeight: 1920,
                useWebWorker: true
            };

            try {
                const compressedFile = await imageCompression(file, options);
                return new Promise((resolve, reject) => {
                    const reader = new FileReader();
                    reader.onload = (upload) => {
                        const photoData = {
                            projectId: project.project_id,
                            projectSectionRowId: currentRowId,
                            imageBlob: upload.target.result
                        };
                        resolve(photoData);
                    };
                    reader.onerror = reject;
                    reader.readAsDataURL(compressedFile);
                });
            } catch (error) {
                console.error("Error compressing file:", error);
                throw error;
            }
        };

        try {
            setLoading(true);
            const compressedPhotos = await Promise.all(files.map(compressAndReadFile));
            photosToAdd.push(...compressedPhotos);

            if (navigator.onLine) {
                await uploadPhotos(photosToAdd);
            } else {
                photosToAdd.forEach(photo => savePhoto(photo));
                setLoading(false);
                alert('No internet connection. Photos have been saved locally, please press the orange re-upload button from the day of view when reconnected to internet.');
                setCachedPhotos(true);
            }
        } catch (error) {
            console.error('Error handling files:', error);
            setLoading(false);
        }
    };

    const uploadPhotos = async (photosToAdd) => {
        const chunkSize = 5; // Number of photos to upload in parallel
        for (let i = 0; i < photosToAdd.length; i += chunkSize) {
            const chunk = photosToAdd.slice(i, i + chunkSize);
            await Promise.all(chunk.map(async (photo) => {
                try {
                    const response = await axios.put(`${process.env.REACT_APP_BACKEND}/api/photos/projects`, {
                        projectId: project.project_id,
                        photosToAdd: [photo]
                    });
                    if (response.status !== 200) {
                        throw new Error('Upload failed');
                    }
                } catch (error) {
                    console.error('Error uploading photo:', error);
                    await savePhoto(photo); // Save failed upload to IndexedDB
                    setCachedPhotos(true);
                }
            }));
        }
        refreshProject().then(() => setLoading(false));
    };

    const addComment = async (projectSectionRowId, comment) => {
        try {
            setLoading(true);
            setSavingComment(projectSectionRowId)
            const response = await axios.put(`${process.env.REACT_APP_BACKEND}/api/photos/projects`, { projectId: project.project_id, commentsToAdd: [{ projectSectionRowId: projectSectionRowId, comment }] });
            if (response.status === 200) {
                refreshProject().then(() => {
                    setLoading(false);
                    setSavingComment(null)
                });
            }
        } catch (error) {
            console.error('Error adding comment:', error);
        }
    };

    const addCrewNotes = async (projectSectionRowId, notes) => {
        try {
            setLoading(true);
            setSavingNote(projectSectionRowId)
            const response = await axios.put(`${process.env.REACT_APP_BACKEND}/api/photos/projects`, { projectId: project.project_id, notesToAdd: [{ projectSectionRowId: projectSectionRowId, notes }] });
            if (response.status === 200) {
                refreshProject().then(() => {
                    setLoading(false);
                    setSavingNote(null)
                });
            }
        } catch (error) {
            console.error('Error adding note:', error);
        }
    };


    const handleFlagClick = async (rowId, isRed) => {
        let currentFlag = flaggedRows[rowId];
        let newFlag;

        if (currentFlag === isRed) {
            newFlag = null; // Reset flag if the same flag is clicked again
        } else {
            newFlag = isRed; // Toggle to the new flag state
        }

        try {
            setLoading(true);
            const response = await axios.put(`${process.env.REACT_APP_BACKEND}/api/photos/projects`, { projectId: project.project_id, flagsToAdd: [{ projectSectionRowId: rowId, flag: newFlag }] });
            if (response.status === 200) {
                setFlaggedRows((prevState) => ({
                    ...prevState,
                    [rowId]: newFlag
                }));
                refreshProject().then(() => setLoading(false));
            }
        } catch (error) {
            console.error('Error setting flag:', error);
        }
    };

    const countRequiredPhotos = () => {
        let count = 0;
        project.sections.forEach(section => {
            section.rows.forEach(row => {
                if (row.required && (!row.photos || row.photos.length === 0)) count++;
            });
            section.collections.forEach(collection => {
                collection.rows.forEach(row => {
                    if (row.required && (!row.photos || row.photos.length === 0)) count++;
                });
            });
        });
        return count;
    };

    const deleteImage = async () => {
        try {
            const photosToRemove = [imageToShow.id];
            setLoading(true);
            setShowingImage(false);
            setImageToShow(null);
            const response = await axios.put(`${process.env.REACT_APP_BACKEND}/api/photos/projects`, { projectId: project.project_id, photosToRemove });
            if (response.status === 200) {
                refreshProject().then(() => setLoading(false));
            }

        } catch (error) {
            console.error('Error deleting image:', error);

        }
    }

    return (
        <>
            <ViewImage
                imageToShow={imageToShow}
                open={showingImage}
                setOpen={setShowingImage}
                handleDelete={deleteImage}
                incrementImage={incrementImage}
            />
            <LoadingScreen open={loading} setOpen={setLoading} />

            <Transition.Root show={open} as={Fragment}>
                <Dialog as="div" className="fixed inset-0 z-10 overflow-y-auto" onClose={showingImage ? () => { } : () => setOpen(false)}>
                    <div className="flex min-h-screen items-center justify-center p-4 text-center">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                        </Transition.Child>

                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                        >
                            <Dialog.Panel className="relative w-full max-w-5xl transform overflow-hidden rounded-lg bg-white p-2 text-left align-middle shadow-xl transition-all">
                                <div className="px-4 py-3 sm:px-6 text-center border-b border-gray-200">
                                    <h3 className="text-lg leading-6 font-medium text-gray-900">
                                        Document install progress for job close out with photos
                                    </h3>
                                </div>
                                <Tab.Group selectedIndex={selectedSection} onChange={setSelectedSection}>
                                    <Tab.List className="flex p-1 space-x-1 rounded-xl">
                                        {project.sections.map((section) => (
                                            <Tab
                                                key={section.section_id}
                                                className={({ selected }) =>
                                                    `w-full py-2.5 text-sm leading-5 font-medium rounded-lg focus:outline-none ${selected ? 'bg-gray-500 text-white shadow' : 'bg-white text-gray-700 hover:bg-gray-200'}`
                                                }
                                            >
                                                {section.name}
                                            </Tab>
                                        ))}
                                    </Tab.List>
                                    <div className="border-t border-gray-200 my-4"></div>
                                    <Tab.Panels className="">
                                        {project.sections.map((section) => (
                                            <Tab.Panel
                                                key={section.section_id}
                                                className="p-3 bg-white rounded-xl focus:outline-none ring-white ring-opacity-60"
                                            >
                                                {section.rows.length > 0 && (
                                                    <div>
                                                        {section.rows.map((row) => (
                                                            <div key={row.row_id} className={`border-b py-2 flex justify-between items-center ${row.flagged_bad === true ? 'bg-red-200' : row.flagged_bad === false ? 'bg-green-200' : ''}`}>
                                                                <div className="flex flex-col flex-grow mr-4">
                                                                    <div className="flex items-center">
                                                                        <div className="font-bold mr-2">{row.name}{!row.required && <span className="text-xs">(Optional)</span>}</div>
                                                                        {adminMode && (
                                                                            <div className="flex space-x-1">
                                                                                <FlagIcon
                                                                                    className={`w-7 h-7 rounded-full bg-white p-1 cursor-pointer ${flaggedRows[row.row_id] === false ? 'text-green-600' : 'text-green-200'}`}
                                                                                    onClick={() => handleFlagClick(row.project_section_row_id, false)}
                                                                                />
                                                                                <FlagIcon
                                                                                    className={`w-7 h-7 rounded-full bg-white p-1 cursor-pointer ${flaggedRows[row.row_id] ? 'text-red-600' : 'text-red-200'}`}
                                                                                    onClick={() => handleFlagClick(row.project_section_row_id, true)}
                                                                                />
                                                                            </div>
                                                                        )}
                                                                    </div>
                                                                    <div className="text-sm text-gray-500">{row.description}</div>
                                                                    <div className="flex overflow-x-auto mt-2 items-center gap-2">
                                                                        {row.photos.map((image, index) => (
                                                                            <div
                                                                                key={image.photo_id}
                                                                                className="shrink-0 relative h-20 cursor-pointer"
                                                                                onClick={() => { setImageToShow({ url: image.url, id: image.photo_id, index: index, row: row }); setShowingImage(true) }}>
                                                                                <img src={image.url} alt={`Image ${image.photo_id}`} className="h-full" />
                                                                            </div>
                                                                        ))}
                                                                    </div>
                                                                    {
                                                                        openCrewNotes.includes(row.project_section_row_id) ? (
                                                                            <div className="mt-2">
                                                                                <>
                                                                                    <textarea
                                                                                        placeholder="Add notes (optional)"
                                                                                        className="w-full border rounded-md px-3 py-1.5 text-sm shadow-sm resize-none"
                                                                                        value={crewNotes[row.project_section_row_id] || ''}
                                                                                        onChange={(e) => updateRowNote(row.project_section_row_id, e.target.value)}
                                                                                    />
                                                                                    <button
                                                                                        className="px-3 text-sm py-1 bg-gray-400 text-white rounded-md shadow hover:bg-gray-700 transition-colors duration-200"
                                                                                        onClick={() => setOpenCrewNotes(openCrewNotes.filter(id => id !== row.project_section_row_id))}
                                                                                    >
                                                                                        Close
                                                                                    </button>
                                                                                    <button
                                                                                        className="ml-2 px-3 py-1 text-sm bg-blue-600 text-white rounded-md shadow hover:bg-blue-700 transition-colors duration-200"
                                                                                        onClick={() => addCrewNotes(row.project_section_row_id, crewNotes[row.project_section_row_id])}
                                                                                    >
                                                                                        {savingNote === row.project_section_row_id ? 'Saving...' : 'Save'}
                                                                                    </button>
                                                                                </>
                                                                            </div>
                                                                        )
                                                                            :
                                                                            row.crew_notes ?
                                                                                (
                                                                                    (
                                                                                        <div className='mt-2 flex items-center'>
                                                                                            <p className='italic text-sm'>Notes: {row.crew_notes ? row.crew_notes : ''}</p>
                                                                                            <PencilIcon
                                                                                                className='w-3 h-3 ml-2 cursor-pointer hover:text-blue-600 transition-colors duration-200'
                                                                                                onClick={() => setOpenCrewNotes([...openCrewNotes, row.project_section_row_id])}

                                                                                            />
                                                                                        </div>

                                                                                    )
                                                                                )
                                                                                :
                                                                                (
                                                                                    <div className="mt-2">
                                                                                        <button
                                                                                            className="px-3 py-1 bg-blue-600 text-white rounded-md shadow hover:bg-blue-700 transition-colors duration-200"
                                                                                            onClick={() => setOpenCrewNotes([...openCrewNotes, row.project_section_row_id])}
                                                                                        >
                                                                                            Add Notes
                                                                                        </button>
                                                                                    </div>
                                                                                )
                                                                    }

                                                                    {row.flagged_bad && (
                                                                        <div className="mt-2">
                                                                            {(adminMode && openFeedbackRows.includes(row.project_section_row_id)) ? (
                                                                                <>
                                                                                    <textarea
                                                                                        placeholder="Enter reason for flagging"
                                                                                        className="w-full border rounded-md px-3 py-1.5 text-sm shadow-sm resize-none"
                                                                                        value={rowComments[row.project_section_row_id] || ''}
                                                                                        onChange={(e) => updateRowComment(row.project_section_row_id, e.target.value)}
                                                                                    />
                                                                                    <button
                                                                                        className="px-3 text-sm py-1 bg-gray-400 text-white rounded-md shadow hover:bg-gray-700 transition-colors duration-200"
                                                                                        onClick={() => setOpenFeedbackRows(openCrewNotes.filter(id => id !== row.project_section_row_id))}
                                                                                    >
                                                                                        Close
                                                                                    </button>
                                                                                    <button
                                                                                        className="ml-2 px-3 py-1 text-sm bg-blue-600 text-white rounded-md shadow hover:bg-blue-700 transition-colors duration-200"
                                                                                        onClick={() => addComment(row.project_section_row_id, rowComments[row.project_section_row_id])}
                                                                                    >
                                                                                        {savingComment === row.project_section_row_id ? 'Saving...' : 'Save'}
                                                                                    </button>
                                                                                </>
                                                                            ) : (

                                                                                <div className='mt-2 flex items-center'>
                                                                                    <p className='italic'>Feedback: {row.comments ? row.comments : ''}</p>
                                                                                    <PencilIcon
                                                                                        className='w-3 h-3 ml-2 cursor-pointer hover:text-blue-600 transition-colors duration-200'
                                                                                        onClick={() => setOpenFeedbackRows([...openFeedbackRows, row.project_section_row_id])}

                                                                                    />
                                                                                </div>

                                                                            )}
                                                                        </div>
                                                                    )}
                                                                </div>
                                                                <button
                                                                    className="flex-none text-center justify-center items-center w-9 h-9 rounded bg-gray-200 p-2 text-gray-500 hover:text-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
                                                                    onClick={() => handlePhotoUpload(row.project_section_row_id)}
                                                                >
                                                                    <PlusIcon className="" />
                                                                </button>
                                                            </div>
                                                        ))}
                                                    </div>
                                                )}
                                                {section.collections.length > 0 && (
                                                    <div>
                                                        {section.collections.map((collection) => (
                                                            <div key={collection.collection_id} className="border-t py-2">
                                                                <div
                                                                    className="flex items-center cursor-pointer"
                                                                    onClick={() => toggleCollapse(collection.collection_id)}
                                                                >
                                                                    <div className={`font-bold ${collection.rows.some(row => row.flagged_bad) ? 'bg-red-200' : ''}`}>{collection.name}</div>
                                                                    <div className="ml-auto">
                                                                        {collapsedCollections[collection.collection_id] ? (
                                                                            <ChevronDownIcon className="h-5 w-5" />
                                                                        ) : (
                                                                            <ChevronUpIcon className="h-5 w-5" />
                                                                        )}
                                                                    </div>

                                                                </div>
                                                                <div className={`${!collapsedCollections[collection.collection_id] ? "ml-4 mt-2" : 'mt-2'}`}>
                                                                    <div className="text-sm text-gray-500 mb-2">{collection.description}</div>
                                                                </div>
                                                                {!collapsedCollections[collection.collection_id] && (
                                                                    <div className="ml-4 mt-2">
                                                                        {collection.rows.map((row) => (
                                                                            <div
                                                                                key={row.row_id}
                                                                                className={`border-b py-2 flex justify-between items-center ${row.flagged_bad === true ? 'bg-red-200' : row.flagged_bad === false ? 'bg-green-200' : ''}`}>
                                                                                <div className="flex flex-col flex-grow mr-4">
                                                                                    <div className="flex items-center">
                                                                                        <div className="font-bold mr-2">{row.name}{!row.required && <span className="ml-2 text-xs">(Optional)</span>}</div>
                                                                                        {adminMode && (
                                                                                            <div className="flex space-x-1">
                                                                                                <FlagIcon
                                                                                                    className={`w-7 h-7 rounded-full bg-white p-1 cursor-pointer ${flaggedRows[row.row_id] === false ? 'text-green-600' : 'text-green-200'}`}
                                                                                                    onClick={() => handleFlagClick(row.project_section_row_id, false)}
                                                                                                />
                                                                                                <FlagIcon
                                                                                                    className={`w-7 h-7 rounded-full bg-white p-1 cursor-pointer ${flaggedRows[row.row_id] ? 'text-red-600' : 'text-red-200'}`}
                                                                                                    onClick={() => handleFlagClick(row.project_section_row_id, true)}
                                                                                                />
                                                                                            </div>
                                                                                        )}
                                                                                    </div>
                                                                                    <div className="text-sm text-gray-500">{row.description}</div>
                                                                                    <div className="flex overflow-x-auto mt-2 items-center gap-2">
                                                                                        {row.photos.map((image, index) => (
                                                                                            <div
                                                                                                key={image.photo_id}
                                                                                                className="shrink-0 relative h-20 cursor-pointer"
                                                                                                onClick={() => { setImageToShow({ url: image.url, id: image.photo_id, index: index, row: row }); setShowingImage(true) }}>
                                                                                                <img src={image.url} alt={`Image ${image.photo_id}`} className="h-full" />
                                                                                            </div>
                                                                                        ))}
                                                                                    </div>
                                                                                    {
                                                                                        openCrewNotes.includes(row.project_section_row_id) ? (
                                                                                            <div className="mt-2">
                                                                                                <>
                                                                                                    <textarea
                                                                                                        placeholder="Add notes (optional)"
                                                                                                        className="w-full border rounded-md px-3 py-1.5 text-sm shadow-sm resize-none"
                                                                                                        value={crewNotes[row.project_section_row_id] || ''}
                                                                                                        onChange={(e) => updateRowNote(row.project_section_row_id, e.target.value)}
                                                                                                    />
                                                                                                    <button
                                                                                                        className="px-3 text-sm py-1 bg-gray-400 text-white rounded-md shadow hover:bg-gray-700 transition-colors duration-200"
                                                                                                        onClick={() => setOpenCrewNotes(openCrewNotes.filter(id => id !== row.project_section_row_id))}
                                                                                                    >
                                                                                                        Close
                                                                                                    </button>
                                                                                                    <button
                                                                                                        className="ml-2 px-3 py-1 text-sm bg-blue-600 text-white rounded-md shadow hover:bg-blue-700 transition-colors duration-200"
                                                                                                        onClick={() => addCrewNotes(row.project_section_row_id, crewNotes[row.project_section_row_id])}
                                                                                                    >
                                                                                                        {savingNote === row.project_section_row_id ? 'Saving...' : 'Save'}
                                                                                                    </button>
                                                                                                </>
                                                                                            </div>
                                                                                        )
                                                                                            :
                                                                                            row.crew_notes ?
                                                                                                (
                                                                                                    (
                                                                                                        <div className='mt-2 flex items-center'>
                                                                                                            <p className='italic text-sm'>Notes: {row.crew_notes ? row.crew_notes : ''}</p>
                                                                                                            <PencilIcon
                                                                                                                className='w-3 h-3 ml-2 cursor-pointer hover:text-blue-600 transition-colors duration-200'
                                                                                                                onClick={() => setOpenCrewNotes([...openCrewNotes, row.project_section_row_id])}

                                                                                                            />
                                                                                                        </div>

                                                                                                    )
                                                                                                )
                                                                                                :
                                                                                                (
                                                                                                    <div className="mt-2">
                                                                                                        <button
                                                                                                            className="px-3 py-1 bg-blue-600 text-white rounded-md shadow hover:bg-blue-700 transition-colors duration-200"
                                                                                                            onClick={() => setOpenCrewNotes([...openCrewNotes, row.project_section_row_id])}
                                                                                                        >
                                                                                                            Add Notes
                                                                                                        </button>
                                                                                                    </div>
                                                                                                )
                                                                                    }
                                                                                    {row.flagged_bad && (
                                                                                        <div className="mt-2">
                                                                                            {(adminMode && openFeedbackRows.includes(row.project_section_row_id)) ? (
                                                                                                <>
                                                                                                    <textarea
                                                                                                        placeholder="Enter reason for flagging"
                                                                                                        className="w-full border rounded-md px-3 py-1.5 text-sm shadow-sm resize-none"
                                                                                                        value={rowComments[row.project_section_row_id] || ''}
                                                                                                        onChange={(e) => updateRowComment(row.project_section_row_id, e.target.value)}
                                                                                                    />
                                                                                                    <button
                                                                                                        className="px-3 text-sm py-1 bg-gray-400 text-white rounded-md shadow hover:bg-gray-700 transition-colors duration-200"
                                                                                                        onClick={() => setOpenFeedbackRows(openCrewNotes.filter(id => id !== row.project_section_row_id))}
                                                                                                    >
                                                                                                        Close
                                                                                                    </button>
                                                                                                    <button
                                                                                                        className="ml-2 px-3 py-1 text-sm bg-blue-600 text-white rounded-md shadow hover:bg-blue-700 transition-colors duration-200"
                                                                                                        onClick={() => addComment(row.project_section_row_id, rowComments[row.project_section_row_id])}
                                                                                                    >
                                                                                                        {savingComment === row.project_section_row_id ? 'Saving...' : 'Save'}
                                                                                                    </button>
                                                                                                </>
                                                                                            ) : (

                                                                                                <div className='mt-2 flex items-center'>
                                                                                                    <p className='italic'>Feedback: {row.comments ? row.comments : ''}</p>
                                                                                                    <PencilIcon
                                                                                                        className='w-3 h-3 ml-2 cursor-pointer hover:text-blue-600 transition-colors duration-200'
                                                                                                        onClick={() => setOpenFeedbackRows([...openFeedbackRows, row.project_section_row_id])}

                                                                                                    />
                                                                                                </div>

                                                                                            )}
                                                                                        </div>
                                                                                    )}
                                                                                </div>
                                                                                <button
                                                                                    className="flex-none text-center justify-center items-center w-9 h-9 rounded bg-gray-200 p-2 text-gray-500 hover:text-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
                                                                                    onClick={() => handlePhotoUpload(row.project_section_row_id)}
                                                                                >
                                                                                    <PlusIcon className="" />
                                                                                </button>
                                                                            </div>
                                                                        ))}
                                                                    </div>
                                                                )}
                                                            </div>
                                                        ))}
                                                    </div>
                                                )}
                                            </Tab.Panel>
                                        ))}
                                    </Tab.Panels>
                                </Tab.Group>
                                <div className="mt-4 flex flex-col items-center w-full">
                                    <button
                                        type="button"
                                        className="w-full mx-4 inline-flex justify-center rounded-md border border-transparent bg-gray-300 px-4 py-2 text-sm font-medium text-white hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                                        onClick={() => setOpen(false)}
                                    >
                                        {loading ?
                                            (<p className='text-gray-700'>Loading...</p>)
                                            : (
                                                'Close'
                                            )}
                                    </button>
                                    <div className="text-center mt-2 text-blue-500">
                                        {countRequiredPhotos()} required photos remaining
                                    </div>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </Dialog>
            </Transition.Root>
            <input type="file" ref={fileInputRef} style={{ display: 'none' }} accept="image/*" onChange={handleFileChange} multiple />
        </>
    );
}

export default ViewPhotos;
