import React, { useEffect } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { XMarkIcon, ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/solid';

function ViewImage({ imageToShow, open, setOpen, handleDelete, incrementImage }) {
    const handleClose = (event) => {
        event.stopPropagation(); // Stop click event from propagating to parent elements
        setOpen(false);
    };

    const handleDownload = (event) => {
        event.preventDefault(); // Prevent default behavior which might interfere with the custom logic
        const link = document.createElement('a');
        link.href = imageToShow.url;
        link.download = 'downloadedImage'; // Provide a default filename for the download
        document.body.appendChild(link); // Append to body
        link.click(); // Simulate click
        document.body.removeChild(link); // Clean up and remove the element
    };

    const enhancedHandleDelete = (event) => {
        event.preventDefault(); // Prevent default behavior which might interfere with the custom logic
        handleDelete();
    };

    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.key === 'ArrowRight') {
                incrementImage(1);
            } else if (event.key === 'ArrowLeft') {
                incrementImage(-1);
            }
        };

        window.addEventListener('keydown', handleKeyDown);

        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [incrementImage]);



    return (
        <Transition.Root show={open} as="div">
            <Dialog as="div" className="fixed inset-0 z-10 overflow-y-auto" onClose={handleClose}>
                <div className="flex min-h-screen items-center justify-center p-4 text-center">
                    <Transition.Child
                        as="div"
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                        className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
                        onClick={handleClose}
                    />
                    <Transition.Child
                        as="div"
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 scale-95"
                        enterTo="opacity-100 scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 scale-100"
                        leaveTo="opacity-0 scale-95"
                    >
                        <Dialog.Panel className="relative w-full max-w-md sm:max-w-5xl transform overflow-hidden rounded-lg bg-white bg-opacity-70 p-2 text-left align-middle shadow-xl transition-all">
                            <div className="absolute top-0 right-0 pt-4 pr-4 z-50">
                                <button
                                    type="button"
                                    className="rounded-full p-1 bg-gray-200 bg-opacity-80 text-red-500 hover:bg-red-500 hover:text-white focus:outline-none"
                                    onClick={handleClose}
                                >
                                    <XMarkIcon className="h-4 w-4" aria-hidden="true" />
                                </button>
                            </div>
                            <img src={imageToShow?.url} alt="Displayed" className="w-full h-auto z-50" />
                            <div className="z-20 absolute inset-0 flex items-center justify-between  group-hover:opacity-100 transition-opacity">
                                <button
                                    type="button"
                                    className={`p-2 cursor-default`}
                                    onClick={() => incrementImage(-1)}
                                >
                                    <ChevronLeftIcon
                                        className={`${imageToShow?.index === 0 ? 'text-transparent cursor-default' : 'text-gray-700 cursor-pointer hover:text-gray-300'} h-8 w-8 `}
                                    />
                                </button>
                                <button
                                    type="button"
                                    className="p-2 cursor-default"
                                    onClick={() => incrementImage(1)}
                                >
                                    <ChevronRightIcon
                                        className={`${imageToShow?.index === imageToShow?.row?.photos?.length - 1 ? 'text-transparent cursor-default' : 'text-gray-700 cursor-pointer hover:text-gray-300'} h-8 w-8 `}
                                    />
                                </button>
                            </div>
                            <div className="mt-2 flex justify-around p-2 z-20">
                                <button
                                    type="button"
                                    className="z-20 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                                    onClick={handleDownload}
                                    onTouchEnd={handleDownload}
                                >
                                    Download
                                </button>
                                <button
                                    type="button"
                                    className="z-20 bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
                                    onClick={enhancedHandleDelete}
                                    onTouchEnd={enhancedHandleDelete}
                                >
                                    Delete
                                </button>
                            </div>
                        </Dialog.Panel>
                    </Transition.Child>
                </div>
            </Dialog>
        </Transition.Root>
    );
}

export default ViewImage;