import { Fragment, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import axios from 'axios';

export default function NewSection({ open, setOpen, refreshSections }) {
    const [sectionName, setSectionName] = useState('');
    const [rows, setRows] = useState([{ name: '', required: false, description: '' }]);
    const [collections, setCollections] = useState([{ name: '', description: '', rows: [{ name: '', required: false, description: '' }] }]);
    const [loading, setLoading] = useState(false);

    const addRow = () => {
        setRows([...rows, { name: '', required: false }]);
    };

    const addCollection = () => {
        setCollections([...collections, { name: '', description: '', rows: [{ name: '', required: false, description: '' }] }]);
    };

    const addRowToCollection = (collectionIndex) => {
        const newCollections = [...collections];
        newCollections[collectionIndex].rows.push({ name: '', required: false });
        setCollections(newCollections);
    };

    const updateCollectionName = (index, name) => {
        const newCollections = [...collections];
        newCollections[index].name = name;
        setCollections(newCollections);
    };

    const updateCollectionDescription = (index, description) => {
        const newCollections = [...collections];
        newCollections[index].description = description;
        setCollections(newCollections);
    };

    const updateRowInCollection = (collectionIndex, rowIndex, updatedRow) => {
        const newCollections = [...collections];
        const collection = newCollections[collectionIndex];
        collection.rows[rowIndex] = { ...collection.rows[rowIndex], ...updatedRow };
        setCollections(newCollections);
    };

    const deleteCollection = (index) => {
        const newCollections = collections.filter((_, i) => i !== index);
        setCollections(newCollections);
    };

    const updateRowName = (index, name) => {
        const newRows = [...rows];
        newRows[index].name = name;
        setRows(newRows);
    };

    const updateRowDescription = (index, description) => {
        const newRows = [...rows];
        newRows[index].description = description;
        setRows(newRows);
    };

    const updateRowRequired = (index, required) => {
        const newRows = [...rows];
        newRows[index].required = required;
        setRows(newRows);
    };

    const deleteRow = (index) => {
        const newRows = rows.filter((_, i) => i !== index);
        setRows(newRows);
    };

    const canCreateSection = () => {
        return sectionName.trim() !== '' && (rows.some(row => row.name.trim() !== '') || collections.some(collection => collection.name.trim() !== ''));
    };

    const createSection = () => {
        setLoading(true);
        const newSection = {
            name: sectionName,
            rows: rows.filter(row => row.name !== ''),
            collections: collections
                .filter(collection => collection.name.trim() !== '')
                .map(collection => ({
                    name: collection.name,
                    description: collection.description,
                    rows: collection.rows.filter(row => row.name !== '')
                }))
        };
        axios.post(`${process.env.REACT_APP_BACKEND}/api/photos/sections`, newSection).then(() => {
            refreshSections();
            setOpen(false);
            setSectionName('');
            setRows([{ name: '', required: false }]);
            setCollections([{ name: '', description: '', rows: [{ name: '', required: false }] }]);
            setLoading(false);
        });
    };

    return (
        <Transition.Root show={open} as={Fragment}>
            <Dialog as="div" className="relative z-10 overflow-y-auto" onClose={() => setOpen(false)}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                <div className="fixed inset-0 z-10 overflow-y-auto">
                    <div className="flex min-h-full items-center justify-center p-4 text-center">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <Dialog.Panel className="relative w-full max-w-4xl transform rounded-lg bg-white p-6 text-left shadow-xl transition-all overflow-auto">
                                <Dialog.Title as="h3" className="text-lg font-semibold leading-6 text-gray-900">
                                    Create New Section
                                </Dialog.Title>
                                <div className=' max-h-[70vh] overflow-y-auto'>
                                    <div className="mt-2">
                                        <input
                                            type="text"
                                            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                            placeholder="Section Name"
                                            value={sectionName}
                                            onChange={(e) => setSectionName(e.target.value)}
                                        />
                                    </div>
                                    <div className="mt-4">
                                        {rows.map((row, index) => (
                                            <div key={index} className="flex flex-col mt-4">
                                                <div className="flex items-center">
                                                    <input
                                                        type="text"
                                                        className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                                        placeholder={`Row ${index + 1} Name`}
                                                        value={row.name}
                                                        onChange={(e) => updateRowName(index, e.target.value)}
                                                    />
                                                    <div className="flex items-center ml-2">
                                                        <input
                                                            type="checkbox"
                                                            checked={row.required}
                                                            onChange={(e) => updateRowRequired(index, e.target.checked)}
                                                            className="form-checkbox h-4 w-4 text-indigo-600 transition duration-150 ease-in-out"
                                                        />
                                                        <label className="ml-2 text-sm text-gray-600" htmlFor={`required-${index}`}>Required</label>
                                                    </div>
                                                    <button
                                                        onClick={() => deleteRow(index)}
                                                        className="ml-2 p-2 rounded-full text-gray-500 hover:text-red-500 focus:outline-none"
                                                    >
                                                        <XMarkIcon className="h-4 w-4" />
                                                    </button>
                                                </div>
                                                <textarea
                                                    className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                                    placeholder="Description"
                                                    value={row.description}
                                                    onChange={(e) => updateRowDescription(index, e.target.value)}
                                                    rows="1"
                                                ></textarea>
                                            </div>
                                        ))}
                                        <button
                                            type="button"
                                            className="mt-4 inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white bg-gray-600 hover:bg-gray-700 focus:outline-none"
                                            onClick={addRow}
                                        >
                                            + Add Row
                                        </button>
                                    </div>
                                    <div className="mt-4">
                                        {collections.map((collection, cIndex) => (
                                            <div key={cIndex} className="mt-4 p-4 border border-gray-300 rounded-md">
                                                <div className="flex justify-between">
                                                    <input
                                                        type="text"
                                                        className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                                        placeholder={`Collection ${cIndex + 1} Name`}
                                                        value={collection.name}
                                                        onChange={(e) => updateCollectionName(cIndex, e.target.value)}
                                                    />
                                                    <button
                                                        onClick={() => deleteCollection(cIndex)}
                                                        className="ml-2 p-2 rounded-full text-gray-500 hover:text-red-500 focus:outline-none"
                                                    >
                                                        <XMarkIcon className="h-4 w-4" />
                                                    </button>
                                                </div>
                                                <textarea
                                                    className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                                    placeholder="Description"
                                                    value={collection.description}
                                                    onChange={(e) => updateCollectionDescription(cIndex, e.target.value)}
                                                    rows="1"
                                                />
                                                {collection.rows.map((row, rIndex) => (
                                                    <>
                                                        <div key={rIndex} className="flex items-center mt-2">
                                                            <input
                                                                type="text"
                                                                className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                                                placeholder={`Row ${rIndex + 1} Name`}
                                                                value={row.name}
                                                                onChange={(e) => updateRowInCollection(cIndex, rIndex, { name: e.target.value })}
                                                            />
                                                            <input
                                                                type="checkbox"
                                                                checked={row.required}
                                                                onChange={(e) => updateRowInCollection(cIndex, rIndex, { required: e.target.checked })}
                                                                className="ml-2 form-checkbox h-4 w-4 text-indigo-600 transition duration-150 ease-in-out"
                                                            />
                                                            <label className="ml-2 text-sm text-gray-600" htmlFor={`required-${cIndex}-${rIndex}`}>Required</label>
                                                            <button
                                                                onClick={() => {
                                                                    const newCollections = [...collections];
                                                                    newCollections[cIndex].rows.splice(rIndex, 1);
                                                                    setCollections(newCollections);
                                                                }}
                                                                className="ml-2 p-2 rounded-full text-gray-500 hover:text-red-500 focus:outline-none"
                                                            >
                                                                <XMarkIcon className="h-4 w-4" />
                                                            </button>
                                                        </div>
                                                        <textarea
                                                            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                                            placeholder={`Row ${rIndex + 1} Description`}
                                                            value={row.description}
                                                            onChange={(e) => updateRowInCollection(cIndex, rIndex, { description: e.target.value })}
                                                            rows="1"
                                                        />
                                                    </>
                                                ))}
                                                <button
                                                    type="button"
                                                    className="mt-2 inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white bg-gray-600 hover:bg-gray-700 focus:outline-none"
                                                    onClick={() => addRowToCollection(cIndex)}
                                                >
                                                    + Add Row to Collection
                                                </button>
                                            </div>
                                        ))}
                                        <button
                                            type="button"
                                            className="mt-4 inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white bg-gray-600 hover:bg-gray-700 focus:outline-none"
                                            onClick={addCollection}
                                        >
                                            + Add Collection
                                        </button>
                                    </div>
                                </div>
                                <div className="mt-6">
                                    <button
                                        type="button"
                                        className={`inline-flex w-full justify-center rounded-md px-4 py-2 text-sm font-semibold text-white shadow-sm ${canCreateSection() ? 'bg-indigo-600 hover:bg-indigo-500' : 'bg-indigo-400 cursor-not-allowed'} focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600`}
                                        onClick={createSection}
                                        disabled={!canCreateSection()}
                                    >
                                        {loading ? (
                                            <div className="spinner-border animate-spin inline-block w-4 h-4 border-2 rounded-full" role="status">
                                                <span className='visually-hidden sr-only'>Loading...</span>
                                            </div>
                                        ) : (
                                            'Create Section'
                                        )}
                                    </button>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    );
}