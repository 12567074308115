import React, { useEffect, useState, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { getPhotos, clearPhotos } from '../utils/indexedDBUtil';
import { HomeIcon, WrenchIcon, UserIcon, ChevronRightIcon } from '@heroicons/react/20/solid';
import ViewPhotos from './ViewPhotos';
import PhoneModal from './PhoneModal';
import Cookies from 'js-cookie';
import { jsPDF } from 'jspdf';
import 'jspdf-autotable';
import logo from '../images/1earthsolar-logo.png';

const DayOfView = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [project, setProject] = useState(null);
    const [showPhotos, setShowPhotos] = useState(false);
    const [showPhoneModal, setShowPhoneModal] = useState(false);
    const [phoneModalInfo, setPhoneModalInfo] = useState(null);
    const [adminMode, setAdminMode] = useState(Cookies.get('uid') !== undefined);
    const [cachedPhotos, setCachedPhotos] = useState(false);
    const [exporting, setExporting] = useState(false);
    const [loadingResubmit, setLoadingResubmit] = useState(false);
    const [exportDropdownOpen, setExportDropdownOpen] = useState(false);
    const [selectedSections, setSelectedSections] = useState([]);

    const dropdownRef = useRef(null);

    useEffect(() => {
        fetchProject();
    }, [id]);

    useEffect(() => {
        const checkForCachedPhotos = async () => {
            const photos = await getPhotos();
            setCachedPhotos(photos.length > 0);
        };

        checkForCachedPhotos();
    }, []);

    useEffect(() => {
        fetchProject();
    }, [id]);

    useEffect(() => {
        // Close dropdown when clicking outside
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setExportDropdownOpen(false);
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const fetchProject = async () => {
        const source = axios.CancelToken.source();
        setTimeout(() => {
            source.cancel('Operation canceled by the user.');
        }, 1000);

        try {
            const response = await axios.get(`${process.env.REACT_APP_BACKEND}/api/photos/project?projectId=${id}`, {
                cancelToken: source.token
            });
            setProject(response.data);
        } catch (error) {
            if (axios.isCancel(error)) {
                fetchProject(); // Retry fetching the project
            } else {
                console.error('Failed to fetch project', error);
            }
        }
    };

    const handleCloseOut = async () => {
        if (adminMode) {
            const response = await axios.post(`${process.env.REACT_APP_BACKEND}/api/photos/projects/closeout/complete`, { projectId: id });
            alert('Project closed out successfully');
        } else {
            const response = await axios.post(`${process.env.REACT_APP_BACKEND}/api/photos/projects/closeout`, { projectId: id });
            setPhoneModalInfo({
                title: 'Call Thibaut for Close Out',
                number: '567-239-5079',
                text: 'Please call Thibaut to close out the install.'
            });
            setShowPhoneModal(true);
        }
    };

    const uploadPhotos = async (photosToAdd) => {
        try {
            const response = await axios.put(`${process.env.REACT_APP_BACKEND}/api/photos/projects`, { projectId: project.project_id, photosToAdd });
            if (response.status === 200) {
                fetchProject();
            }
        } catch (error) {
            console.error('Error uploading photos:', error);
        }
    };

    const getBase64ImageFromUrl = (imageUrl) => {
        return new Promise((resolve, reject) => {
            const img = new Image();
            img.crossOrigin = 'Anonymous';
            img.onload = () => {
                const canvas = document.createElement('canvas');
                canvas.width = img.width;
                canvas.height = img.height;
                const ctx = canvas.getContext('2d');
                ctx.drawImage(img, 0, 0);
                const dataURL = canvas.toDataURL('image/jpeg');
                resolve(dataURL);
            };
            img.onerror = reject;
            img.src = imageUrl;
        });
    };

    const handleExport = async () => {
        setExporting(true);
        const doc = new jsPDF();

        // Determine which sections to export
        const sectionsToExport = selectedSections.length > 0
            ? project.sections.filter(section => selectedSections.includes(section.name))
            : project.sections;

        // Initial settings
        const pageHeight = doc.internal.pageSize.height;
        const topMargin = 10; // top margin for starting content on a new page
        const bottomMargin = 20; // bottom margin to avoid cutting off images

        // Function to check and add new page if needed
        const checkAndAddNewPage = (yOffset, elementHeight = 0) => {
            if (yOffset + elementHeight > pageHeight - bottomMargin) {
                doc.addPage();
                return topMargin; // Reset yOffset for new page
            }
            return yOffset;
        };

        // Add logo
        doc.addImage(logo, 'PNG', 10, 10, 50, 20);

        // Divider line below the logo
        doc.setDrawColor(0);
        doc.setLineWidth(0.5);
        doc.line(10, 35, 200, 35);

        // Add project information header
        doc.setFontSize(16);
        doc.setFont("helvetica", "bold");
        doc.text('Project Information', 10, 45);

        // Project Name on its own line
        doc.setFontSize(12);
        doc.setFont("helvetica", "normal");
        doc.text(`Project Name: ${project.name}`, 10, 60);

        // Two columns for other details
        doc.setFontSize(10);
        let yOffset = 70;
        const rightColumnX = 105;
        doc.text(`Customer Name: ${project.customer_name}`, 10, yOffset);
        doc.text(`Customer Address: ${project.customer_address}`, rightColumnX, yOffset);
        yOffset = checkAndAddNewPage(yOffset + 10);
        doc.text(`Customer Phone: ${project.customer_phone}`, 10, yOffset);
        doc.text(`Install Date: ${project.install_date}`, rightColumnX, yOffset);
        yOffset = checkAndAddNewPage(yOffset + 10);
        doc.text(`Utility Company: ${project.utility_company}`, 10, yOffset);
        doc.text(`System Size: ${project.system_size}`, rightColumnX, yOffset);
        yOffset = checkAndAddNewPage(yOffset + 10);
        doc.text(`Panel Type: ${project.panel_type}`, 10, yOffset);
        doc.text(`Roof Type: ${project.roof_type}`, rightColumnX, yOffset);
        yOffset = checkAndAddNewPage(yOffset + 10);
        doc.text(`Site Improvements: ${project.site_improvements}`, 10, yOffset);
        doc.text(`Panel Count: ${project.panel_count}`, rightColumnX, yOffset);
        yOffset = checkAndAddNewPage(yOffset + 10);
        doc.text(`Inverter Type: ${project.inverter_type}`, 10, yOffset);
        doc.text(`Scheduled Duration: ${project.scheduled_duration}`, rightColumnX, yOffset);
        yOffset = checkAndAddNewPage(yOffset + 10);

        // Divider line before sections
        doc.setDrawColor(0);
        doc.setLineWidth(0.5);
        doc.line(10, yOffset, 200, yOffset);
        yOffset += 10;

        // Sections and collections
        for (const section of sectionsToExport) {
            for (const row of section.rows) {
                doc.setFontSize(10);
                doc.setFont("helvetica", "bold");
                doc.text(`${section.name} / ${row.name}`, 10, yOffset);
                yOffset = checkAndAddNewPage(yOffset + 5);
                doc.setFont("helvetica", "normal");
                doc.text(row.description ? `(${row.description})` : '', 10, yOffset);
                yOffset = checkAndAddNewPage(yOffset + 10);

                // Add images two per row
                let xPosition = 10;
                for (const [index, photo] of row.photos.entries()) {
                    try {
                        const base64Image = await getBase64ImageFromUrl(photo.url);
                        yOffset = checkAndAddNewPage(yOffset, 50);
                        doc.addImage(base64Image, 'JPEG', xPosition, yOffset, 50, 50);
                        xPosition += 60; // Move to the next column position
                        if ((index + 1) % 2 === 0) { // Check if it's the second image in the row
                            yOffset = checkAndAddNewPage(yOffset + 60); // Move to next row after two images
                            xPosition = 10; // Reset to first column
                        }
                    } catch (error) {
                        console.error('Error loading image:', error);
                    }
                }
                if (row.photos.length % 2 !== 0) { // If odd number of photos, adjust yOffset
                    yOffset = checkAndAddNewPage(yOffset + 60);
                }
                doc.text(`${row.name} Notes: ${row.crew_notes || ''}`, 10, yOffset);
                yOffset = checkAndAddNewPage(yOffset + 10);

                // Add a divider line after each row
                doc.setDrawColor(0);
                doc.setLineWidth(0.5);
                doc.line(10, yOffset, 200, yOffset);
                yOffset = checkAndAddNewPage(yOffset + 10);
            }

            for (const collection of section.collections) {
                for (const row of collection.rows) {
                    doc.setFontSize(10);
                    doc.setFont("helvetica", "bold");
                    doc.text(`${section.name} / ${row.name}`, 10, yOffset);
                    yOffset = checkAndAddNewPage(yOffset + 5);
                    doc.setFont("helvetica", "normal");
                    doc.text(row.description ? `(${row.description})` : '', 10, yOffset);
                    yOffset = checkAndAddNewPage(yOffset + 10);

                    // Add images two per row
                    let xPosition = 10;
                    for (const [index, photo] of row.photos.entries()) {
                        try {
                            const base64Image = await getBase64ImageFromUrl(photo.url);
                            yOffset = checkAndAddNewPage(yOffset, 50);
                            doc.addImage(base64Image, 'JPEG', xPosition, yOffset, 50, 50);
                            xPosition += 60; // Move to the next column position
                            if ((index + 1) % 2 === 0) { // Check if it's the second image in the row
                                yOffset = checkAndAddNewPage(yOffset + 60); // Move to next row after two images
                                xPosition = 10; // Reset to first column
                            }
                        } catch (error) {
                            console.error('Error loading image:', error);
                        }
                    }
                    if (row.photos.length % 2 !== 0) { // If odd number of photos, adjust yOffset
                        yOffset = checkAndAddNewPage(yOffset + 60);
                    }
                    doc.text(`${row.name} Notes: ${row.crew_notes || ''}`, 10, yOffset);
                    yOffset = checkAndAddNewPage(yOffset + 10);

                    // Add a divider line after each row
                    doc.setDrawColor(0);
                    doc.setLineWidth(0.5);
                    doc.line(10, yOffset, 200, yOffset);
                    yOffset = checkAndAddNewPage(yOffset + 10);
                }
            }
        }

        // After the existing content, add full-page images
        const allPhotos = sectionsToExport.flatMap(section =>
            section.rows.flatMap(row => row.photos)
                .concat(section.collections.flatMap(collection =>
                    collection.rows.flatMap(row => row.photos)
                ))
        );

        for (const photo of allPhotos) {
            try {
                const base64Image = await getBase64ImageFromUrl(photo.url);
                doc.addPage();
                const pageWidth = doc.internal.pageSize.width;
                const pageHeight = doc.internal.pageSize.height;
                doc.addImage(base64Image, 'JPEG', 0, 0, pageWidth, pageHeight, '', 'FAST');
            } catch (error) {
                console.error('Error loading full-page image:', error);
            }
        }

        // Save the PDF
        doc.save(`${project.name}_export.pdf`);
        setExporting(false);
        setExportDropdownOpen(false); // Close the dropdown after export
    };

    async function uploadPhotosFromDB(retries = 3) {
        if (!navigator.onLine) {
            alert('You are offline. Please connect to the internet to upload photos.');
            return;
        }

        setLoadingResubmit(true);
        const photos = await getPhotos();
        if (photos.length > 0) {
            try {
                await uploadPhotos(photos);
                await clearPhotos();
                setCachedPhotos(false);
            } catch (error) {
                console.error('Error uploading photos:', error);
                if (retries > 0) {
                    console.log(`Retrying upload. Attempts left: ${retries - 1}`);
                    await uploadPhotosFromDB(retries - 1);
                } else {
                    alert('Failed to upload photos after multiple attempts. Please try again later.');
                }
            } finally {
                setLoadingResubmit(false);
            }
        }
    }

    const toggleSection = (sectionName) => {
        setSelectedSections(prev => {
            if (prev.includes(sectionName)) {
                return prev.filter(name => name !== sectionName);
            } else {
                return [...prev, sectionName];
            }
        });
    };

    if (!project) {
        return <div>Loading...</div>;
    }

    return (
        <>
            <ViewPhotos
                open={showPhotos}
                setOpen={setShowPhotos}
                project={project}
                refreshProject={fetchProject}
                setCachedPhotos={setCachedPhotos} />
            <PhoneModal
                open={showPhoneModal}
                setOpen={setShowPhoneModal}
                title={phoneModalInfo?.title}
                number={phoneModalInfo?.number}
                text={phoneModalInfo?.text}
            />
            <div className="min-h-screen flex flex-col bg-gray-50">
                <div className="sticky top-0 z-10 flex h-16 shrink-0 items-center gap-x-4 border-b border-gray-200 bg-gray-50 px-4 shadow-sm sm:gap-x-6 sm:px-6 lg:px-8 justify-between">
                    <span
                        className="text-lg font-bold text-black cursor-pointer"
                        onClick={() => navigate('/')}
                    >
                        1EarthSolar
                        <span className="text-orange-500">OS</span>
                    </span>
                    <div className="flex items-center justify-center space-x-2">
                        {adminMode && (
                            <div className="relative" ref={dropdownRef}>
                                <button
                                    className="hidden sm:flex bg-gray-200 text-gray-500 px-4 py-2 rounded hover:bg-gray-400"
                                    onClick={() => setExportDropdownOpen(!exportDropdownOpen)}
                                >
                                    {exporting ? 'Exporting...' : 'Export'}
                                </button>
                                {exportDropdownOpen && (
                                    <div className="absolute right-0 mt-2 w-64 bg-white border border-gray-200 rounded-md shadow-lg z-20">
                                        <div className="p-4">
                                            <h3 className="text-sm font-semibold mb-2">Select Sections to Export</h3>
                                            <div className="max-h-60 overflow-y-auto">
                                                {project.sections.map(section => (
                                                    <div key={section.id} className="flex items-center mb-2">
                                                        <input
                                                            type="checkbox"
                                                            id={`section-${section.id}`}
                                                            checked={selectedSections.includes(section.name)}
                                                            onChange={() => toggleSection(section.name)}
                                                            className="h-4 w-4 text-indigo-600 border-gray-300 rounded"
                                                        />
                                                        <label htmlFor={`section-${section.id}`} className="ml-2 text-sm text-gray-700">
                                                            {section.name}
                                                        </label>
                                                    </div>
                                                ))}
                                            </div>
                                            <button
                                                className="mt-4 w-full bg-blue-600 text-white px-3 py-2 rounded hover:bg-blue-700"
                                                onClick={handleExport}
                                                disabled={exporting}
                                            >
                                                {exporting ? 'Exporting...' : 'Export Selected'}
                                            </button>
                                        </div>
                                    </div>
                                )}
                            </div>
                        )}
                        {(adminMode && project.ready_for_close) || !adminMode ? (
                            <button
                                className="hidden sm:flex bg-gray-500 text-white px-4 py-2 rounded hover:bg-gray-800"
                                onClick={handleCloseOut}
                            >
                                {adminMode ? 'Complete Close Out' : 'Close Out'}
                            </button>
                        ) : null}
                        <button
                            className="hidden sm:flex bg-green-600 text-white px-4 py-2 rounded hover:bg-green-800"
                            onClick={() => setShowPhotos(true)}
                        >
                            Upload Photos
                        </button>
                    </div>
                </div>
                <div className="fixed bottom-0 left-0 right-0 flex flex-col items-center justify-center p-3 sm:hidden">
                    <button
                        className="bg-green-600 text-white px-4 py-2 rounded shadow-lg mb-2 w-full"
                        onClick={() => setShowPhotos(true)}
                    >
                        Upload Photos
                    </button>
                    {cachedPhotos && (
                        <button
                            className="bg-orange-600 text-white px-4 py-2 rounded shadow-lg mb-2 w-full"
                            onClick={() => uploadPhotosFromDB()}
                        >
                            {loadingResubmit ? 'Resubmitting...' : 'Resubmit Photos'}
                        </button>
                    )}
                    {(adminMode && project.ready_for_close) || !adminMode ? (
                        <button
                            className="bg-gray-600 text-white px-4 py-2 rounded shadow-lg w-full"
                            onClick={handleCloseOut}
                        >
                            {adminMode ? 'Complete Close Out' : 'Close Out'}
                        </button>
                    ) : null}
                </div>

                <div className="p-4 sm:p-0">
                    <div className="mx-auto max-w-5xl">
                        <div className="flex flex-1 flex-col md:flex-row justify-between items-start mt-6">
                            <div className="bg-white pt-3 rounded-lg shadow-lg w-full max-w-md mb-4 md:mb-0 md:mr-6">
                                <div className="text-center mb-4">
                                    <h2 className="text-xl font-bold">Install for {project.install_date}</h2>
                                </div>
                                <hr className="my-4 border-gray-300" />
                                <div>
                                    <div
                                        className="flex items-center justify-between px-2 pb-4 border-b rounded cursor-pointer hover:bg-gray-50"
                                        onClick={() => {
                                            setPhoneModalInfo({
                                                title: "Call Customer",
                                                number: project.customer_phone
                                            });
                                            setShowPhoneModal(true);
                                        }}
                                    >
                                        <div className="flex items-center">
                                            <UserIcon className="h-7 w-7" />
                                            <div className="ml-2">
                                                <p className="font-semibold">{project.customer_name}</p>
                                                <p className='font-light text-sm'>{project.customer_phone}</p>
                                            </div>
                                        </div>
                                        <i className="fas fa-chevron-right"></i>
                                    </div>

                                    <div className="flex items-center justify-between px-2 py-4 border-b rounded">
                                        <div className="flex items-center">
                                            <HomeIcon className="h-7 w-7" />
                                            <div className="ml-2">
                                                <p className="font-semibold">{project.customer_address}</p>
                                            </div>
                                        </div>
                                        <i className="fas fa-chevron-right"></i>
                                    </div>

                                    <div className="flex items-center justify-between p-2 rounded">
                                        <div className="flex items-center">
                                            <WrenchIcon className="h-7 w-7" />
                                            <div className="ml-2">
                                                <p className="font-semibold">{`${project.system_size} Project`}</p>
                                                <p>{`${project.panel_count} panels, ${project.roof_type}`}</p>
                                            </div>
                                        </div>
                                        <i className="fas fa-chevron-right"></i>
                                    </div>
                                </div>
                            </div>
                            <div className="w-full sm:w-2/3">
                                <button
                                    className="w-full bg-white text-black font-medium p-5 border rounded-md flex justify-between items-center space-x-2 shadow-md"
                                    onClick={() => setShowPhotos(true)}>
                                    <span>Document Installation</span>
                                    <ChevronRightIcon className="h-5 w-5" />
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default DayOfView;
